import dbService from '@/services/dbService.js'
import bF from '@/services/basicFunctions.js'
import router from '@/router'
import moment from 'moment'

export const namespaced = true

export const state = {
     accessData: [],
     menuStatus: false,
     VAT: 19,
     deleteinfo: {},
     backlink: '',
     user: {},
     loginStatus: false,
     gSearchTerm: '',
     folderView: 'list',
}

export const mutations = {
     UPDATE_MENUSTATUS(state, data) {
          state.menuStatus = data
     },
     UPDATE_BACKLINK(state, data) {
          state.backlink = data
     },
     UPDATE_GSEARCHTERM(state, data) {
          state.gSearchTerm = data
     },
     UPDATE_FOLDERVIEW(state, data) {
          state.folderView = data
     },
     FILL_USERDATA(state, data) {
          state.user = data
     },
     FILL_ACCESSDATA(state, data) {
          state.accessData = data
     },
}

export const actions = {
     checkLogin({ commit, dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         // CREATE SESSION
                         let sessionToken = bF.generate_token(50)
                         let userID = response.data[0].ID
                         let userLevel = response.data[0].level
                         let sData = {
                              userID: userID,
                              token: sessionToken,
                              query: 'setSession',
                         }
                         dispatch('setSession', sData).then(
                              (responseSession) => {
                                   if (responseSession == 'OK') {
                                        // SET LOCAL VARIABLE
                                        let userData = JSON.stringify({
                                             userID: userID,
                                             token: sessionToken,
                                             level: userLevel,
                                             creationdate: moment().format(
                                                  'YYYY-MM-DD HH:mm:ss'
                                             ),
                                        })
                                        localStorage.setItem('user', userData)
                                        // FILL USERDATA
                                        commit(
                                             'FILL_USERDATA',
                                             response.data[0]
                                        )
                                        dispatch('init_Data', '', {
                                             root: true,
                                        })
                                        router.push({ path: '/dashboard' })
                                        return 'OK'
                                   } else {
                                        commit(
                                             'messages/ADD_MESSAGE',
                                             {
                                                  text: 'Login fehlgeschlagen! Session konnte nicht gesetzt werden!',
                                                  icon: 'info',
                                                  color: 'red',
                                                  time: 3500,
                                             },
                                             { root: true }
                                        )
                                   }
                              }
                         )
                         return 'OK'
                    } else {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Die Kombination aus Benutzername und Passwort ist leider falsch oder Ihr Profil wurde zwischenzeitlich deaktiviert!',
                                   icon: 'info',
                                   color: 'red',
                                   time: 3500,
                              },
                              { root: true }
                         )
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     setSession(base, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200 && response.data != 'Error') {
                         return 'OK'
                    } else {
                         return 'Error'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     destroySession() {
          let userData = JSON.parse(localStorage.getItem('user'))
          let data = {
               query: 'closeSession',
               token: userData.token,
          }
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200 && response.data != 'Error') {
                         localStorage.removeItem('user')
                         router.push({ path: '/login' })
                         return 'OK'
                    } else {
                         return 'Error'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_UserData({ commit }, data) {
          let userData = JSON.parse(localStorage.getItem('user'))
          data.token = userData.token
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200 && response.data != 'Error') {
                         commit('FILL_USERDATA', response.data[0])
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_AccessInfo({ commit }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200 && response.data != 'Error') {
                         commit('FILL_ACCESSDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
}

export const getters = {
     backLink: (state) => {
          return state.backlink
     },
     usernameline: (state) => {
          let user = state.user
          let nameline = (user.firstname + ' ' + user.lastname).trim()
          if (user.lastname || user.firstname) {
               return nameline
          } else {
               return ''
          }
     },
     userinitials: (state) => {
          if (state.user.lastname) {
               let initials =
                    state.user.firstname.slice(0, 1).toUpperCase() +
                    state.user.lastname.slice(0, 1).toUpperCase()
               return initials
          }
     },
}
