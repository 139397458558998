/* 
INFORMATION REGARDING THE DATA - OBJECT
- data always has to be an object. 
- it needs to include an query key which provides the query name for the backend script
- it always has to provide the user_token as well for access
*/

import bF from '@/services/basicFunctions.js'
import axios from 'axios'

const apiClient = axios.create({
     baseURL: bF.baseURL(),
     withCredentials: false, // This is the default
     headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
     },
     // headers: {
     //      'X-Requested-With': 'XMLHttpRequest',
     //      'Content-Type': 'application/json',
     //      Accept: 'application/json',
     //  },
     timeout: 10000,
})

const queryString = function (data) {
     let query = 'queries.php?'
     let i = 0
     let connector = ''
     if (data) {
          let userData = JSON.parse(localStorage.getItem('user'))
          if (userData) {
               data.userID = userData.userID
               data.token = userData.token
          }
          for (const [key, value] of Object.entries(data)) {
               if (i != 0) {
                    connector = '&'
               } else {
                    connector = ''
               }

               // ENCODE GET SPECIAL CHARACTERS
               let word = value
               let signs = [
                    { sign: '&', code: '$und$' },
                    { sign: '+', code: '$plus$' },
                    { sign: '=', code: '$ist$' },
               ]
               if (word && typeof word == 'string') {
                    signs.forEach((s) => {
                         word = word.replace(s.sign, s.code)
                    })
               }
               // ENCODE END

               query = query + connector + key + '=' + word
               i++
          }
     }
     return query
}

export default {
     runQuery(data) {
          return apiClient.get(queryString(data))
     },
}
