var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navigation"},[_c('div',{staticClass:"navbox",class:_vm.menuStatus ? 'active' : ''},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.menuStatus),expression:"!menuStatus"}],staticClass:"navicon",on:{"click":_vm.toggleMenu}},[_c('font-awesome-icon',{attrs:{"icon":"bars"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuStatus),expression:"menuStatus"}],staticClass:"navcontent"},[_c('div',{staticClass:"closer",on:{"click":_vm.toggleMenu}},[_vm._v("∧")]),_vm._l((_vm.navigation),function(nav,index){return _c('div',{key:index},[(!nav.sub && _vm.checkNavAccess(nav))?_c('router-link',{attrs:{"to":nav.link}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":nav.icon}})],1),_c('div',{staticClass:"navlabel"},[_vm._v(_vm._s(nav.name))])])]):_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkNavAccess(nav)),expression:"checkNavAccess(nav)"}]},[_c('div',{staticClass:"box",on:{"click":function($event){return _vm.openInst(index)}}},[_c('div',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":index == _vm.openBullet
                                                  ? 'angle-down'
                                                  : 'angle-right'}})],1),_c('div',{staticClass:"navlabel"},[_vm._v(_vm._s(nav.name))])]),_c('div',{staticClass:"subbox",class:index == _vm.openBullet ? 'active' : ''},_vm._l((nav.sub),function(sub,index2){return _c('div',{key:index2},[(
                                             !sub.sub &&
                                             _vm.checkNavAccess(sub)
                                        )?_c('router-link',{attrs:{"to":sub.link}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":sub.icon}})],1),_c('div',{staticClass:"navlabel"},[_vm._v(" "+_vm._s(sub.name)+" ")])])]):_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkNavAccess(sub)),expression:"checkNavAccess(sub)"}]},[_c('div',{staticClass:"box",on:{"click":function($event){return _vm.openSubInst(index2)}}},[_c('div',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":index2 ==
                                                            _vm.openSubBullet
                                                                 ? 'angle-down'
                                                                 : 'angle-right'}})],1),_c('div',{staticClass:"navlabel"},[_vm._v(" "+_vm._s(sub.name)+" ")])]),_c('div',{staticClass:"subbox deep",class:index2 == _vm.openSubBullet
                                                       ? 'active'
                                                       : ''},_vm._l((sub.sub),function(sub2,index3){return _c('div',{key:index3},[(
                                                            !sub2.sub &&
                                                            _vm.checkNavAccess(
                                                                 sub2
                                                            )
                                                       )?_c('router-link',{attrs:{"to":sub2.link}},[_c('font-awesome-icon',{attrs:{"icon":sub2.icon}}),_vm._v(" "+_vm._s(sub2.name))],1):_vm._e()],1)}),0)])],1)}),0)])],1)}),_c('a',[_c('div',{staticClass:"box"},[_c('div',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":"power-off"}})],1),_c('div',{staticClass:"navlabel",on:{"click":_vm.logout}},[_vm._v(" Ausloggen ")])])])],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }