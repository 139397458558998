//import dbService from '@/services/dbService.js'

import basicFunctions from '@/services/basicFunctions.js'
export const namespaced = true

export const state = {
     messages: [],
}

export const mutations = {
     ADD_MESSAGE(state, data) {
          let token = basicFunctions.generate_token(6)
          let color = ''
          switch (data.color) {
               case 'green':
                    color = '#d9f7ed'
                    break
               case 'red':
                    color = '#e5b5c3' //'#ffd1d1'
                    break
               case 'yellow':
                    color = '#fcf0b3'
                    break
               case 'grey':
                    color = '#ebebeb'
                    break
          }
          var message = {
               ID: token,
               text: data.text,
               color: color,
               icon: data.icon,
          }
          state.messages.push(message)
          setTimeout(function () {
               var filMes = []
               filMes = state.messages.filter((m) => m.ID != token)
               state.messages = filMes
          }, data.time)
     },
}

export const actions = {
     add_Message({ commit }, data) {
          commit('ADD_MESSAGE', data)
     },
}
