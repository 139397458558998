import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import moment from 'moment'

Vue.use(VueRouter)

const routes = [
     {
          path: '/dashboard',
          name: 'Dashboard',
          component: () => import('../views/Dashboard.vue'),
     },
     {
          path: '/login',
          name: 'Login',
          component: () => import('../views/LoginPage.vue'),
     },
     {
          path: '/filemanager/:ID',
          name: 'Filemanager',
          component: () => import('../views/FileManager.vue'),
     },
     {
          path: '/kundenverwaltung',
          name: 'Customers',
          component: () => import('../views/Customers.vue'),
     },
     {
          path: '/kunde/:ID',
          name: 'Customer',
          component: () => import('../views/Customer.vue'),
     },
     {
          path: '/investitionsplan',
          name: 'Investmentplan',
          component: () => import('../views/InvestmentPlan.vue'),
     },
     {
          path: '/projekte/:type',
          name: 'Projects',
          component: () => import('../views/Projects.vue'),
     },
     {
          path: '/projekt/:ID',
          name: 'Projekt',
          component: () => import('../views/Project.vue'),
     },
     {
          path: '/einheit/:ID',
          name: 'Einheit',
          component: () => import('../views/ProjectUnit.vue'),
     },
     {
          path: '/vertraege/:area/:type',
          name: 'Agreements',
          component: () => import('../views/Agreements.vue'),
     },
     {
          path: '/vertrag/:ID',
          name: 'Agreement',
          component: () => import('../views/Agreement.vue'),
     },
     {
          path: '/katalog',
          name: 'Catalog',
          component: () => import('../views/Catalog.vue'),
     },
     {
          path: '/leistung/:ID',
          name: 'Service',
          component: () => import('../views/Service.vue'),
     },
     {
          path: '/media/:type',
          name: 'Media',
          component: () => import('../views/ImageDatabase.vue'),
     },
     {
          path: '/verwaltung',
          name: 'Management',
          component: () => import('../views/Management.vue'),
     },
     {
          path: '/nachrichten',
          name: 'Nachrichten',
          component: () => import('../views/News.vue'),
     },
     {
          path: '/kategorieverwaltung/bilddatenbank',
          name: 'CategoryManagement',
          component: () => import('../views/CategoryManagement.vue'),
     },
     {
          path: '/kategorieverwaltung/katalog',
          name: 'CatalogCategoryManagement',
          component: () => import('../views/CatalogCategoryManagement.vue'),
     },
     {
          path: '/nachricht/:ID',
          name: 'Nachricht',
          component: () => import('../views/Message.vue'),
     },
     {
          path: '/benutzerverwaltung',
          name: 'Benutzerverwaltung',
          component: () => import('../views/UserManagement.vue'),
     },
     {
          path: '/profil/:ID',
          name: 'Profil',
          component: () => import('../views/Profile.vue'),
     },
     {
          path: '/updatepassword',
          name: 'UpdatePassword',
          component: () => import('../views/UpdatePassword.vue'),
     },
     {
          path: '/suchergebnisse',
          name: 'SearchResults',
          component: () => import('../views/SearchResults.vue'),
     },
     {
          path: '/bilddatenbankupload',
          name: 'ImageDatabaseUpload',
          component: () => import('../views/ImageDatabaseUpload.vue'),
     },
]

const router = new VueRouter({
     mode: 'history',
     base: process.env.BASE_URL,
     routes,
})

router.beforeEach((to, from, next) => {
     // MENU FUNCTION DESKTOP / MOBILE
     if (window.screen.width < 450) {
          store.commit('basics/UPDATE_MENUSTATUS', false)
     }
     // ROUTING
     let userData = JSON.parse(localStorage.getItem('user'))
     //console.log(userData)
     let latestLogin = moment().subtract(12, 'hours')
     if (
          userData != null &&
          moment(userData.creationdate).isAfter(latestLogin)
     ) {
          store.state.basics.loginStatus = true
          if (to.path == '/' || to.path == '/login') {
               next({ path: '/dashboard' })
          } else {
               next()
          }
     } else {
          store.state.basics.loginStatus = false
          localStorage.removeItem('user')
          if (to.path != '/login') {
               next({ path: '/login' })
          } else {
               next()
          }
     }
     // Weiche für Suchfeld löschen
     if (to.path != '/suchergebnisse') {
          store.state.basics.gSearchTerm = ''
     }
})

export default router
