import dbService from '@/services/dbService.js'
import bF from '@/services/basicFunctions.js'

export const namespaced = true

export const state = {
     projectsdata: [],
     projectUnitsdata: [],
     unitTypesFlat: [
          { text: 'Gewerbe', value: 'GW' },
          { text: 'Mehrfamilienhaus', value: 'MFH' },
          { text: 'Tagespflege', value: 'TP' },
          { text: 'Wohnung', value: 'WHG' },
     ],
     unitTypesHouse: [
          { text: 'Doppelhaushälfte', value: 'DHH' },
          { text: 'Einfamilienhaus', value: 'EFH' },
          { text: 'Mehrfamilienhaus', value: 'MFH' },
          { text: 'Reihenendhaus', value: 'REH' },
          { text: 'Reihenmittelhaus', value: 'RMH' },
          { text: 'Wohnung', value: 'WHG' },
     ],
     projectStatusOptions: [
          'Projektierungsphase',
          'Verkaufsphase',
          'Planungsphase',
          'Bauphase',
          'Abgeschlossen',
     ],
     unitStatusOptions: [
          'Projektierungsphase',
          'Verkaufsphase',
          'Planungsphase',
          'Bauphase',
          'Gewährleistungsphase',
          'Abgeschlossen',
     ],
     corporatesdata: [],
}

export const mutations = {
     FILL_PROJECTSDATA(state, data) {
          state.projectsdata = data
     },
     FILL_PROJECTUNITSDATA(state, data) {
          state.projectUnitsdata = data
     },
     FILL_CORPORATESDATA(state, data) {
          state.corporatesdata = data
     },
}

export const actions = {
     get_Projects({ commit }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         commit('FILL_PROJECTSDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_ProjectUnits({ commit }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         commit('FILL_PROJECTUNITSDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_Corporates({ commit }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         commit('FILL_CORPORATESDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     add_Project({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         let projectID = response.data[0].ID
                         data.units.forEach((u) => {
                              u.projectID = projectID
                              u.ask = true
                              dispatch('add_ProjectUnit', u)
                         })
                         dispatch('get_Projects', {
                              query: 'getProjects',
                         })
                         dispatch('get_ProjectUnits', {
                              query: 'getProjectUnits',
                         })
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     add_ProjectUnit({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         if (!data.ask) {
                              dispatch('get_Projects', {
                                   query: 'getProjects',
                              })
                              dispatch('get_ProjectUnits', {
                                   query: 'getProjectUnits',
                              })
                         }
                         return response.data
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_Project({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200 && response.data == 'Done') {
                         dispatch('get_Projects', {
                              query: 'getProjects',
                         })
                         dispatch('get_ProjectUnits', {
                              query: 'getProjectUnits',
                         })
                         dispatch(
                              'agreements/get_Agreements',
                              {
                                   query: 'getAgreements',
                              },
                              { root: true }
                         )
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_ProjectUnit({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200 && response.data == 'Done') {
                         dispatch('get_ProjectUnits', {
                              query: 'getProjectUnits',
                         })
                         dispatch('get_Projects', {
                              query: 'getProjects',
                         })
                         dispatch(
                              'agreements/get_Agreements',
                              {
                                   query: 'getAgreements',
                              },
                              { root: true }
                         )
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
}

export const getters = {
     project: (state) => (ID) => {
          let project = []
          if (state.projectsdata) {
               project = state.projectsdata.find((p) => p.ID == ID)
          }
          return project
     },
     projects: (state) => {
          let projects = []
          if (state.projectsdata) {
               projects = state.projectsdata
          }
          return projects
     },
     projectUnit: (state) => (ID) => {
          let unit = []
          if (state.projectUnitsdata) {
               unit = state.projectUnitsdata.find((u) => u.ID == ID)
          }
          return unit
     },
     projectUnits: (state) => (ID) => {
          let units = []
          if (state.projectUnitsdata) {
               units = state.projectUnitsdata.filter(
                    (u) => u.projectID == ID /* && u.title.trim() != '' */
               )
          }
          return bF.sortList('title', units, true)
     },
     projectList: (state) => {
          let projects = []
          if (state.projectsdata) {
               state.projectsdata.forEach((p) => {
                    if (p.title != '') {
                         projects.push({
                              value: p.ID,
                              text: p.title,
                              status: p.status,
                              type: p.type,
                         })
                    }
               })
          }
          return bF.sortList('value', projects, true)
     },
     projectUnitsList: (state) => {
          let units = []
          if (state.projectUnitsdata) {
               state.projectUnitsdata.forEach((u) => {
                    if (u.title != '') {
                         units.push({
                              value: u.ID,
                              text: u.title + ' - ' + u.nameline,
                              projectID: u.projectID,
                         })
                    }
               })
          }
          return bF.sortList('text', units, true)
     },
     customerProjectUnits: (state) => (ID) => {
          let units = []
          if (state.projectUnitsdata) {
               units = state.projectUnitsdata.filter(
                    (u) => u.customer1ID == ID || u.customer2ID == ID
               )
          }
          return bF.sortList('title', units, true)
     },
     projectUnitTypesList: (state) => (ID) => {
          let list = []
          let data
          let projecttype
          if (ID != 'wohnungsbau' && ID != 'hausbau') {
               state.projectsdata.forEach((p) => {
                    if (p.ID == ID) {
                         projecttype = p.type
                    }
               })
          } else {
               projecttype = ID
          }

          if (projecttype == 'wohnungsbau') {
               data = state.unitTypesFlat
          } else if (projecttype == 'hausbau') {
               data = state.unitTypesHouse
          }
          if (data) {
               list = data
          }
          return list
     },
     corporateOptions: (state) => (type) => {
          let output = []
          if (state.corporatesdata) {
               state.corporatesdata.forEach((c) => {
                    if (
                         (c.type == type || c.type == 'allgemein') &&
                         c.status == 'A'
                    ) {
                         output.push({
                              value: c.ID,
                              text: c.title,
                         })
                    }
               })
          }
          return output
     },
}
