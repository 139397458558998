<template>
     <div class="navigation">
          <div class="navbox" :class="menuStatus ? 'active' : ''">
               <div class="navicon" v-show="!menuStatus" @click="toggleMenu">
                    <font-awesome-icon icon="bars" />
               </div>
               <div class="navcontent" v-show="menuStatus">
                    <div class="closer" @click="toggleMenu">&and;</div>
                    <div v-for="(nav, index) in navigation" :key="index">
                         <!-- DIRECT ROUTE INSTANCE 1 -->
                         <router-link
                              :to="nav.link"
                              v-if="!nav.sub && checkNavAccess(nav)"
                         >
                              <div class="box">
                                   <div class="icon">
                                        <font-awesome-icon :icon="nav.icon" />
                                   </div>
                                   <div class="navlabel">{{ nav.name }}</div>
                              </div>
                         </router-link>
                         <!-- OPENER INSTANCE 1 -->
                         <a v-else v-show="checkNavAccess(nav)">
                              <div class="box" @click="openInst(index)">
                                   <div class="icon">
                                        <font-awesome-icon
                                             :icon="
                                                  index == openBullet
                                                       ? 'angle-down'
                                                       : 'angle-right'
                                             "
                                        />
                                   </div>
                                   <div class="navlabel">{{ nav.name }}</div>
                              </div>
                              <div
                                   :class="index == openBullet ? 'active' : ''"
                                   class="subbox"
                              >
                                   <div
                                        v-for="(sub, index2) in nav.sub"
                                        :key="index2"
                                   >
                                        <!-- DIRECT ROUTE INSTANCE 2 -->
                                        <router-link
                                             v-if="
                                                  !sub.sub &&
                                                  checkNavAccess(sub)
                                             "
                                             :to="sub.link"
                                        >
                                             <div class="box">
                                                  <div class="icon">
                                                       <font-awesome-icon
                                                            :icon="sub.icon"
                                                       />
                                                  </div>
                                                  <div class="navlabel">
                                                       {{ sub.name }}
                                                  </div>
                                             </div>
                                        </router-link>
                                        <a v-else v-show="checkNavAccess(sub)">
                                             <div
                                                  class="box"
                                                  @click="openSubInst(index2)"
                                             >
                                                  <div class="icon">
                                                       <font-awesome-icon
                                                            :icon="
                                                                 index2 ==
                                                                 openSubBullet
                                                                      ? 'angle-down'
                                                                      : 'angle-right'
                                                            "
                                                       />
                                                  </div>
                                                  <div class="navlabel">
                                                       {{ sub.name }}
                                                  </div>
                                             </div>
                                             <div
                                                  :class="
                                                       index2 == openSubBullet
                                                            ? 'active'
                                                            : ''
                                                  "
                                                  class="subbox deep"
                                             >
                                                  <div
                                                       v-for="(
                                                            sub2, index3
                                                       ) in sub.sub"
                                                       :key="index3"
                                                  >
                                                       <router-link
                                                            v-if="
                                                                 !sub2.sub &&
                                                                 checkNavAccess(
                                                                      sub2
                                                                 )
                                                            "
                                                            :to="sub2.link"
                                                            ><font-awesome-icon
                                                                 :icon="
                                                                      sub2.icon
                                                                 "
                                                            />
                                                            {{
                                                                 sub2.name
                                                            }}</router-link
                                                       >
                                                  </div>
                                             </div>
                                        </a>
                                   </div>
                              </div>
                         </a>
                    </div>

                    <a>
                         <div class="box">
                              <div class="icon">
                                   <font-awesome-icon icon="power-off" />
                              </div>
                              <div class="navlabel" @click="logout">
                                   Ausloggen
                              </div>
                         </div>
                    </a>
               </div>
          </div>
     </div>
</template>

<script>
import { access } from '@/mixins/accessrights.js'
export default {
     name: 'main-navigation',
     mixins: [access],
     data() {
          return {
               openBullet: '',
               openSubBullet: '',
               navigation: [
                    {
                         name: 'Dashboard',
                         icon: 'tachometer-alt',
                         link: '/dashboard',
                         access: 'admin,intern,sales',
                    },
                    {
                         name: 'Gruppe',
                         icon: 'angle-right',
                         access: 'admin,intern,sales',
                         sub: [
                              {
                                   name: 'Allgemein',
                                   icon: 'circle',
                                   link: '/filemanager/10',
                                   access: 'admin,intern,sales',
                              },
                              {
                                   name: 'Grundstücksaquise',
                                   icon: 'map',
                                   link: '/filemanager/8',
                                   access: 'admin,intern,sales',
                              },
                              {
                                   name: 'Broschüren',
                                   icon: 'book-open',
                                   link: '/filemanager/9',
                                   access: 'admin,intern,sales',
                              },
                              {
                                   name: 'Mitarbeiterinfo',
                                   icon: 'newspaper',
                                   link: '/filemanager/1',
                                   access: 'admin,intern,sales',
                              },
                              {
                                   name: 'Katalog',
                                   icon: 'shapes',
                                   link: '/katalog',
                                   access: 'admin,intern,sales',
                              },
                              {
                                   name: 'Kundenverwaltung',
                                   icon: 'users',
                                   link: '/kundenverwaltung',
                                   access: 'admin,intern,sales',
                              },
                         ],
                    },
                    {
                         name: 'Hausbau',
                         icon: 'angle-right',
                         access: 'admin,intern,sales',
                         sub: [
                              {
                                   name: 'Projekte',
                                   icon: 'home',
                                   link: '/projekte/hausbau',
                                   access: 'admin,intern,sales',
                              },
                              {
                                   name: 'Zusatzvereinbarungen',
                                   icon: 'pen-alt',
                                   link: '/vertraege/hausbau/zusatzvereinbarungen',
                                   access: 'admin,intern,sales',
                              },
                              // {
                              //      name: '+/- Leistungen',
                              //      icon: 'angle-right',
                              //      access: 'admin,intern,sales',
                              //      sub: [
                              //           {
                              //                name: 'Zusatzausstattungen',
                              //                icon: 'pen-alt',
                              //                link: '/vertraege/hausbau/zusatzausstattungen',
                              //                access: 'admin,intern,sales',
                              //           },
                              //           {
                              //                name: 'Zusatzvereinbarungen',
                              //                icon: 'pen-alt',
                              //                link: '/vertraege/hausbau/zusatzvereinbarungen',
                              //                access: 'admin,intern,sales',
                              //           },
                              //      ],
                              // },
                              {
                                   name: 'Investitionsplan',
                                   icon: 'funnel-dollar',
                                   link: '/filemanager/348',
                                   access: 'admin,intern,sales',
                              },
                              {
                                   name: 'Dokumente',
                                   icon: 'file-alt',
                                   link: '/filemanager/11',
                                   access: 'admin,intern,sales',
                              },
                              // {
                              //      name: 'Broschüren',
                              //      icon: 'book-open',
                              //      link: '/filemanager/12',
                              //      access: 'admin,intern,sales',
                              // },
                         ],
                    },
                    {
                         name: 'Wohungsbau',
                         icon: 'angle-right',
                         access: 'admin,intern,sales',
                         sub: [
                              {
                                   name: 'Projekte',
                                   icon: 'building',
                                   link: '/projekte/wohnungsbau',
                                   access: 'admin,intern,sales',
                              },
                              {
                                   name: 'Zusatzvereinbarungen',
                                   icon: 'pen-alt',
                                   link: '/vertraege/wohnungsbau/zusatzvereinbarungen',
                                   access: 'admin,intern,sales',
                              },
                              // {
                              //      name: '+/- Leistungen',
                              //      icon: 'angle-right',
                              //      access: 'admin,intern,sales',
                              //      sub: [
                              //           {
                              //                name: 'Zusatzausstattungen',
                              //                icon: 'pen-alt',
                              //                link: '/vertraege/wohnungsbau/zusatzausstattungen',
                              //                access: 'admin,intern,sales',
                              //           },
                              //           {
                              //                name: 'Zusatzvereinbarungen',
                              //                icon: 'pen-alt',
                              //                link: '/vertraege/wohnungsbau/zusatzvereinbarungen',
                              //                access: 'admin,intern,sales',
                              //           },
                              //      ],
                              // },
                              {
                                   name: 'Investitionsplan',
                                   icon: 'funnel-dollar',
                                   link: '/filemanager/347',
                                   access: 'admin,intern,sales',
                              },
                              {
                                   name: 'Dokumente',
                                   icon: 'file-alt',
                                   link: '/filemanager/346',
                                   access: 'admin,intern,sales',
                              },
                              {
                                   name: 'Allgemein',
                                   icon: 'circle',
                                   link: '/filemanager/13',
                                   access: 'admin,intern,sales',
                              },
                         ],
                    },
                    {
                         name: 'Immobilienservice',
                         icon: 'angle-right',
                         access: 'admin,intern,sales',
                         sub: [
                              {
                                   name: 'Maklerwissen',
                                   icon: 'book',
                                   link: '/filemanager/14',
                                   access: 'admin,intern,sales',
                              },
                              {
                                   name: 'Ext. Bauträgervertrieb',
                                   icon: 'suitcase',
                                   link: '/filemanager/44',
                                   access: 'admin,intern,sales',
                              },
                              {
                                   name: 'Büro Augsburg',
                                   icon: 'angle-right',
                                   access: 'admin,intern,sharepoint:Augsburg!',
                                   sub: [
                                        {
                                             name: 'Formulare',
                                             icon: 'file-alt',
                                             link: '/filemanager/15',
                                             access: 'admin,intern,sharepoint:Augsburg!',
                                        },
                                        {
                                             name: 'Sharepoint',
                                             icon: 'share-alt',
                                             link: '/filemanager/16',
                                             access: 'admin,intern,sharepoint:Augsburg!',
                                        },
                                   ],
                              },
                              {
                                   name: 'Büro München',
                                   icon: 'angle-right',
                                   access: 'admin,intern,sharepoint:München!',
                                   sub: [
                                        {
                                             name: 'Formulare',
                                             icon: 'file-alt',
                                             link: '/filemanager/17',
                                             access: 'admin,intern,sharepoint:München!',
                                        },
                                        {
                                             name: 'Sharepoint',
                                             icon: 'share-alt',
                                             link: '/filemanager/18',
                                             access: 'admin,intern,sharepoint:München!',
                                        },
                                   ],
                              },
                              {
                                   name: 'Büro Schwabmünchen',
                                   icon: 'angle-right',
                                   access: 'admin,intern,sharepoint:SMÜ,Allgäu!',
                                   sub: [
                                        {
                                             name: 'Formulare',
                                             icon: 'file-alt',
                                             link: '/filemanager/19',
                                             access: 'admin,intern,sharepoint:SMÜ,Allgäu!',
                                        },
                                        {
                                             name: 'Sharepoint Team SMÜ',
                                             icon: 'share-alt',
                                             link: '/filemanager/20',
                                             access: 'admin,intern,sharepoint:SMÜ!',
                                        },
                                        // {
                                        //      name: 'Sharepoint Team Allgäu',
                                        //      icon: 'share-alt',
                                        //      link: '/filemanager/21',
                                        //      access: 'admin,intern,sharepoint:Allgäu!',
                                        // },
                                   ],
                              },
                         ],
                    },
                    {
                         name: 'Bilder/Videos',
                         icon: 'angle-right',
                         access: 'admin,intern,sales',
                         sub: [
                              {
                                   name: 'Bilddatenbank',
                                   icon: 'images',
                                   link: '/media/bdb',
                                   access: 'admin,intern,sales',
                              },
                              {
                                   name: 'Referenzbilder',
                                   icon: 'image',
                                   /* link: '/filemanager/32', */
                                   link: '/media/refb',
                                   access: 'admin,intern,sales',
                              },
                              {
                                   name: 'Videos',
                                   icon: 'video',
                                   /* link: '/filemanager/33', */
                                   link: '/media/vid',
                                   access: 'admin,intern,sales',
                              },
                              {
                                   name: 'Firmenveranstaltungen',
                                   icon: 'glass-cheers',
                                   /* link: '/filemanager/34', */
                                   link: '/media/ff',
                                   access: 'admin,intern,sales',
                              },
                         ],
                    },
                    {
                         name: 'Verwaltung',
                         icon: 'cogs',
                         access: 'admin',
                         sub: [
                              {
                                   name: 'Benutzer',
                                   icon: 'users',
                                   link: '/benutzerverwaltung',
                                   access: 'admin',
                              },
                              {
                                   name: 'Nachrichten',
                                   icon: 'newspaper',
                                   link: '/nachrichten',
                                   access: 'admin',
                              },
                              {
                                   name: 'Kategorien',
                                   icon: 'tasks',
                                   access: 'admin',
                                   sub: [
                                        {
                                             name: 'Bilddatenbank',
                                             icon: 'tasks',
                                             link: '/kategorieverwaltung/bilddatenbank',
                                             access: 'admin',
                                        },
                                        {
                                             name: 'Katalog',
                                             icon: 'tasks',
                                             link: '/kategorieverwaltung/katalog',
                                             access: 'admin',
                                        },
                                   ],
                              },
                              {
                                   name: 'Media Upload',
                                   icon: 'upload',
                                   link: '/bilddatenbankupload',
                                   access: 'admin',
                              },
                         ],
                    },
               ],
          }
     },
     computed: {
          menuStatus() {
               return this.$store.state.basics.menuStatus
          },
     },
     methods: {
          toggleMenu() {
               if (this.menuStatus) {
                    this.$store.commit('basics/UPDATE_MENUSTATUS', false)
               } else {
                    this.$store.commit('basics/UPDATE_MENUSTATUS', true)
               }
          },
          openInst(bullet) {
               if (this.openBullet != bullet) {
                    this.openBullet = bullet
               } else {
                    this.openBullet = ''
               }
          },
          openSubInst(bullet) {
               if (this.openSubBullet != bullet) {
                    this.openSubBullet = bullet
               } else {
                    this.openSubBullet = ''
               }
          },
          logout() {
               this.$store.dispatch('basics/destroySession')
          },
     },
}
</script>

<style lang="sass">
@import "@/assets/SASS/main.sass"
.navbox
     background-color: $darkgrey
     border: 1px solid $darkgrey
     width: 50px
     height: 50px
     border-radius: 50%
     position: fixed
     top: 15vh
     left: 4%
     @media #{$smartphone}
          left: auto
          right: 6%
          top: 3vh
     display: block
     transition: 1s
     z-index: 100
     &.active
          width: 255px
          height: auto
          border-radius: 0
          transition: 1s
          left: 0
          top: 13vh
          @media #{$smartphone}
               left: auto
               right: 0
     .navicon
          color: white
          @include poscenter
          font-size: 1.1rem
     .navcontent
          margin: 0
          padding: 0
          display: block
          position: relative
          .box
               padding: 10px 12px
               a
                    border-bottom: none
          .closer
               position: absolute
               left: 0
               bottom: -25px
               color: white
               background-color: lighten($darkgrey, 15%)
               width: 100%
               height: 25px
               border-radius: 0
               margin: 0
               border: 1px solid lighten($darkgrey, 15%)
               font-size: 1rem
               text-align: center
               transition: 0.5s
               &:hover
                    background-color: $lightred
                    transition: 0.5s
                    svg
                         color: white
               svg
                    font-size: 0.9rem
                    transition: 0.5s
                    margin-top: -10px

          svg
               font-size: 0.7rem
          a
               color: white !important
               margin: 0
               padding: 0
               display: block
               width: 100%
               font-size: 1rem
               transition: 0.5s
               text-transform: uppercase
               letter-spacing: 0.05rem
               border-bottom: 1px solid lighten($darkgrey, 10%)
               .icon
                    width: 1.5rem
                    display: inline-block
                    text-align: center
               .navlabel
                    width: auto
                    margin-left: 6px
                    display: inline-block
               &:hover
                    background-color: lighten($darkgrey, 5%)
                    color: white
                    transition: 0.5s
                    cursor: pointer
               &.active
                    background-color: $lightred
               .subbox
                    margin: 0
                    padding: 0 0 0 8px
                    width: 100%
                    height: 0
                    transform: scaleY(0)
                    transform-origin: left top
                    opacity: 0
                    transition: 0s
                    &.deep
                         a
                              padding: 5px 5px 5px 25px
                    .box
                         padding: 0 0 0 10px
                    a

                         padding: 5px 5px 5px 15px
                         margin: 0
                         text-transform: none
                         font-size: 0.95rem
                         &:hover
                              text-decoration: none
                              background-color: lighten($darkgrey, 10%)
                              transition: 0.5s
                    &.active
                         //display: block
                         margin-top: 5px
                         height: auto
                         opacity: 1
                         transform: scaleY(1)
                         transition: 0.5s
</style>
