import dbService from '@/services/dbService.js'
import bF from '@/services/basicFunctions.js'

export const namespaced = true

export const state = {
     newsdata: [],
}

export const mutations = {
     FILL_NEWSDATA(state, data) {
          state.newsdata = data
     },
}

export const actions = {
     get_News({ commit }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         response.data.forEach((n) => {
                              n.description = bF.decodeHTML(n.description)
                         })
                         commit('FILL_NEWSDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     add_Message({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         dispatch('get_News', {
                              query: 'getNews',
                         })
                         return response.data
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_News({ dispatch }, data) {
          data.description = bF.encodeHTML(data.description)
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200 && response.data == 'Done') {
                         if (data.status != 'X') {
                              dispatch('get_News', {
                                   query: 'getNews',
                              })
                         }
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
}

export const getters = {
     last3news: (state) => {
          let news = []
          if (state.newsdata) {
               state.newsdata.forEach((n) => {
                    if (news.length < 3) {
                         news.push(n)
                    }
               })
          }
          return news
     },
     news: (state) => {
          let news = []
          if (state.newsdata) {
               news = state.newsdata
          }
          return news
     },
     activeNews: (state) => {
          let news = []
          if (state.newsdata) {
               news = state.newsdata.filter((n) => n.status == 'A')
          }
          return news
     },
     message: (state) => (ID) => {
          let message = []
          if (state.newsdata) {
               message = state.newsdata.find((m) => m.ID == ID)
          }
          return message
     },
}
