export default {
     generate_token(length) {
          var a =
               'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split(
                    ''
               )
          var b = []
          for (var i = 0; i < length; i++) {
               var j = (Math.random() * (a.length - 1)).toFixed(0)
               b[i] = a[j]
          }
          return b.join('')
     },
     baseURL() {
          let beURL = 'https://app.layer-portal.de/backend'
          return beURL
     },
     tags() {
          return [
               { tag: '<p>', code: '__p__', back: true },
               { tag: '</p>', code: '__/p__', back: true },
               { tag: '<strong>', code: '__strong__', back: true },
               { tag: '</strong>', code: '__/strong__', back: true },
               { tag: '<b>', code: '__strong__', back: false },
               { tag: '</b>', code: '__/strong__', back: false },
               { tag: '<em>', code: '__em__', back: true },
               { tag: '</em>', code: '__/em__', back: true },
               { tag: '<i>', code: '__em__', back: false },
               { tag: '</i>', code: '__/em__', back: false },
               { tag: '<u>', code: '__u__', back: true },
               { tag: '</u>', code: '__/u__', back: true },
               { tag: '<br>', code: '__br__', back: true },
               { tag: '<br />', code: '__br__', back: true },
               { tag: '<br/>', code: '__br__', back: true },
               { tag: '<span class="ql-cursor">', code: '', back: false },
               { tag: '</span>', code: '', back: false },
               { tag: '<ul>', code: '__ul__', back: true },
               { tag: '</ul>', code: '__ul__', back: true },
               { tag: '<li>', code: '__li__', back: true },
               { tag: '</li>', code: '__/li__', back: true },
          ]
     },
     encodeHTML(text) {
          const tags = this.tags()
          tags.forEach((t) => {
               text = text.replaceAll(t.tag, t.code)
          })
          return text.trim()
     },
     decodeHTML(text) {
          const tags = this.tags()
          tags.forEach((t) => {
               if (t.back) {
                    text = text.replaceAll(t.code, t.tag)
               }
          })
          return text
     },
     sortList(field, data, direction) {
          // direction true = asc / false = desc
          let output = data.sort((a, b) => {
               let valA = a[field].toLowerCase()
               let valB = b[field].toLowerCase()
               if (valA == valB) {
                    return 0
               } else if (
                    (valA < valB && !direction) ||
                    (valA > valB && direction)
               ) {
                    return 1
               } else {
                    return -1
               }
          })
          return output
     },
}
