export const access = {
     computed: {
          user() {
               return this.$store.state.basics.user
          },
          accessInfo() {
               return this.$store.state.basics.accessData
          },
          users() {
               return this.$store.getters['users/activeUserList']
          },
          userLevel() {
               return JSON.parse(localStorage.getItem('user')).level
          },
     },
     methods: {
          accessCheck(action, editor) {
               let aI = this.accessInfo.find((a) => a.action == action)
               if (aI) {
                    if (aI.level.includes(this.user.level)) {
                         return true
                    } else if (aI.editor == 1 && this.user.ID == editor) {
                         return true
                    } else {
                         return false
                    }
               } else {
                    return false
               }
          },
          checkNavAccess(data) {
               if (data.access.includes(this.user.level)) {
                    return true
               } else {
                    if (data.access.includes('sharepoint:')) {
                         let sharepoint = data.access.substring(
                              data.access.lastIndexOf(':') + 1,
                              data.access.lastIndexOf('!')
                         )
                         let userSP = this.user.sharepoints
                         let out = false
                         if (userSP) {
                              if (sharepoint.includes(',')) {
                                   let SParr = sharepoint.split(',')
                                   SParr.forEach((s) => {
                                        if (userSP.includes(s)) {
                                             out = true
                                        }
                                   })
                              } else {
                                   if (userSP.includes(sharepoint)) {
                                        out = true
                                   }
                              }
                         }
                         return out
                    } else {
                         return false
                    }
               }
          },
     },
}
