import dbService from '@/services/dbService.js'
import bF from '@/services/basicFunctions.js'
import axios from 'axios'

export const namespaced = true

export const state = {
     foldersdata: [],
     filesdata: [],
     delFolder: '',
     delFile: '',
     imagedirectory: bF.baseURL() + '/drive/files/',
     imagecategoriesdata: [],
     imagecategorylinksdata: [],
     mediaTypes: [
          { name: 'Bilddatenbank', value: 'bdb' },
          { name: 'Referenzbilder', value: 'refb' },
          { name: 'Videos', value: 'vid' },
          { name: 'Firmenveranstaltungen', value: 'ff' },
     ],
     mimeTypes: [
          { id: 1, extension: 'doc', mime: 'application/msword' },
          { id: 2, extension: 'dot', mime: 'application/msword' },
          {
               id: 3,
               extension: 'docx',
               mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          },
          { id: 4, extension: 'pdf', mime: 'application/pdf' },
          { id: 5, extension: 'txt', mime: 'text/plain' },
          {
               id: 6,
               extension: 'xlsx',
               mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          { id: 7, extension: 'xls', mime: 'application/vnd.ms-excel' },
          { id: 8, extension: 'bmp', mime: 'image/bmp' },
          { id: 9, extension: 'gif', mime: 'image/gif' },
          { id: 10, extension: 'jpg', mime: 'image/jpeg' },
          { id: 11, extension: 'png', mime: 'image/png' },
          {
               id: 12,
               extension: 'odp',
               mime: 'application/vnd.oasis.opendocument.presentation',
          },
          { id: 13, extension: 'pot', mime: 'application/mspowerpoint' },
          {
               id: 14,
               extension: 'pptx',
               mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          },
          {
               id: 15,
               extension: 'ppsx',
               mime: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
          },
          { id: 16, extension: 'ppt', mime: 'application/mspowerpoint' },
          {
               id: 17,
               extension: 'ppsm',
               mime: 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
          },
          {
               id: 18,
               extension: 'potx',
               mime: 'application/vnd.openxmlformats-officedocument.presentationml.template',
          },
          {
               id: 19,
               extension: 'docm',
               mime: 'application/vnd.ms-word.document.macroEnabled.12',
          },
          {
               id: 20,
               extension: 'dotx',
               mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
          },
          { id: 21, extension: 'doc', mime: 'application/kswps' },
          { id: 22, extension: 'xls', mime: 'application/kset' },
          {
               id: 23,
               extension: 'odt',
               mime: 'application/vnd.oasis.opendocument.text',
          },
     ],
}

export const mutations = {
     FILL_FOLDERSDATA(state, data) {
          state.foldersdata = data
     },
     FILL_FILESDATA(state, data) {
          state.filesdata = data
     },
     FILL_IMAGECATEGORIESDATA(state, data) {
          state.imagecategoriesdata = data
     },
     FILL_IMAGECATEGORYLINKSDATA(state, data) {
          state.imagecategorylinksdata = data
     },
}

export const actions = {
     get_ImageCategories({ commit }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         commit('FILL_IMAGECATEGORIESDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_ImageCategoryLinks({ commit }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         commit('FILL_IMAGECATEGORYLINKSDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     addNewCategory({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         dispatch('get_ImageCategories', {
                              query: 'getImageCategories',
                         })
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_ImageDetails({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200 && response.data != 'Error') {
                         dispatch('get_ImageCategories', {
                              query: 'getImageCategories',
                         })
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_ImageCategory({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200 && response.data != 'Error') {
                         dispatch('get_ImageCategories', {
                              query: 'getImageCategories',
                         })
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     delete_Image({ dispatch }, data) {
          data.query = 'deleteImageCategoryLinkByImageID'
          return dispatch('delete_ImageCategoryLink', data).then((response) => {
               if (response == 'OK') {
                    data.query = 'deleteFile'
                    dispatch('delete_File', data).then((response) => {
                         if (response == 'OK') {
                              return 'OK'
                         } else {
                              return 'Error'
                         }
                    })
               }
          })
     },
     delete_ImageCategory({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200) {
                         dispatch('get_ImageCategories', {
                              query: 'getImageCategories',
                         })
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     delete_ImageCategoryLink({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200) {
                         dispatch('get_ImageCategoryLinks', {
                              query: 'getImageCategoryLinks',
                         })
                         console.log('Links deleted')
                         return 'OK'
                    } else {
                         return 'Error'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_Folders({ commit }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         commit('FILL_FOLDERSDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_Files({ commit }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         commit('FILL_FILESDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     add_Folder({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    console.log(response)
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         dispatch('get_Folders', {
                              query: 'getFolders',
                         })
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_Folder({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    //console.log(response)
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         dispatch('get_Folders', {
                              query: 'getFolders',
                         })
                         return 'OK'
                    } else {
                         return 'Error'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_File({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200 && response.data == 'Done') {
                         dispatch('get_Files', { query: 'getFiles' })
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     delete_Folder({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200 && response.data == 'Done') {
                         dispatch('get_Folders', {
                              query: 'getFolders',
                         })
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     delete_File({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200 && response.data == 'Done') {
                         dispatch('get_Files', { query: 'getFiles' })
                         return 'OK'
                    } else {
                         return 'Error'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     delete_ImageInclCat({ dispatch, commit }, imageID) {
          let data = {
               query: 'deleteImageCategoryLinkByImageID',
               ID: imageID,
          }
          dispatch('delete_ImageCategoryLink', data).then((response) => {
               if (response != 'OK') {
                    commit(
                         'messages/ADD_MESSAGE',
                         {
                              text: 'Fehler beim Löschen der Kategorien!',
                              icon: 'info',
                              color: 'red',
                              time: 3500,
                         },
                         { root: true }
                    )
               }
               data = {
                    query: 'delete_Image',
                    ID: imageID,
               }
               dispatch('delete_Image', data).then((response) => {
                    if (response == 'Error') {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Fehler beim Löschen des Bildes!',
                                   icon: 'info',
                                   color: 'red',
                                   time: 3500,
                              },
                              { root: true }
                         )
                    }
               })
          })
     },
     uploadFile({ dispatch }, data) {
          let formData = new FormData()
          let fm_script = bF.baseURL() + '/drive/filemanager.php'
          formData.append('file', data.file)
          formData.append('title', data.title)
          formData.append('description', data.description)
          formData.append('keywords', data.keywords)
          formData.append('relation', data.relation)
          formData.append('folderID', data.folderID)

          return axios
               .post(fm_script, formData, {
                    headers: {
                         'content-Type': 'multipart/form-data',
                    },
               })
               .then((response) => {
                    dispatch('get_Files', { query: 'getFiles' })
                    return response
               })
               .catch((response) => {
                    return response
               })
     },
     add_CategoryLinks({ dispatch }, data) {
          let data2 = []
          data.images.forEach((i) => {
               data.categories.forEach((c) => {
                    data2.push({
                         query: 'addImageCategoryLink',
                         fileID: i,
                         imagecategoryID: c,
                    })
               })
               data2.forEach((d2) => {
                    return dbService
                         .runQuery(d2)
                         .then((response) => {
                              if (
                                   response.status == 200 &&
                                   response.data != 'Not found'
                              ) {
                                   dispatch('get_ImageCategoryLinks', {
                                        query: 'getImageCategoryLinks',
                                   })
                                   return 'OK'
                              }
                         })
                         .catch((error) => {
                              console.log(error)
                         })
               })
          })
          console.log(data2)
     },
}

export const getters = {
     getMimeTypes: (state) => (type) => {
          let mime = []
          if (state.mimeTypes) {
               if (type == '*') {
                    mime = state.mimeTypes
               } else {
                    mime = state.mimeTypes.filter((m) => m.mime.includes(type))
               }
          }
          return mime
     },
     foldersBySuperior: (state) => (ID) => {
          let folders = []
          if (state.foldersdata) {
               folders = state.foldersdata.filter((f) => f.superior == ID)
          }
          return folders
     },
     folders: (state) => (ID) => {
          let folders = []
          if (state.foldersdata) {
               folders = state.foldersdata.filter((f) => f.ID == ID)
          }
          return folders
     },
     folder: (state) => (ID) => {
          let folder = []
          if (state.foldersdata) {
               folder = state.foldersdata.find((f) => f.ID == ID)
          }
          return folder
     },
     filesByFolder: (state) => (ID) => {
          let files = []
          if (state.filesdata) {
               files = state.filesdata.filter((f) => f.folderID == ID)
          }
          return files
     },
     filesByRelation: (state) => (relation) => {
          let files = []
          if (state.filesdata) {
               files = state.filesdata.filter((f) => f.relation == relation)
          }
          return files
     },
     file: (state) => (ID) => {
          let file = []
          if (state.filesdata) {
               file = state.filesdata.find((f) => f.ID == ID)
          }
          return file
     },
     getImageCategoryTree: (state) => (type) => {
          let cat = []
          let data = state.imagecategoriesdata.filter((i) => i.type == type)
          if (data) {
               // 1st Category
               let mainCat = data.filter((i) => i.superior == '0')
               // 2nd Category
               mainCat.forEach((m) => {
                    let c = m
                    let sub = data.filter((i) => i.superior == m.ID)
                    if (sub.length > 0) {
                         c.subcategories = sub
                    }
                    cat.push(c)
               })
               // 3rd Category
               cat.forEach((c) => {
                    if (c.subcategories) {
                         c.subcategories.forEach((s) => {
                              let subdata = data.filter(
                                   (sd) => sd.superior == s.ID
                              )
                              if (subdata.length != 0) {
                                   s.subcategories = subdata
                              }
                         })
                    }
               })
          }
          return cat
     },
     getCategoryByID: (state) => (ID) => {
          let cat = {}
          if (state.imagecategoriesdata) {
               cat = state.imagecategoriesdata.find((c) => c.ID == ID)
          }
          return cat
     },
     getImageCategoryLinks: (state) => {
          let links = {}
          if (state.imagecategorylinksdata) {
               links = state.imagecategorylinksdata
          }
          return links
     },
     getCategoriesByImageID: (state) => (ID) => {
          let cats = []
          if (state.imagecategorylinksdata) {
               cats = state.imagecategorylinksdata.filter((i) => i.fileID == ID)
          }
          return cats
     },
     getCategoriesByCategoryID: (state) => (ID) => {
          let cats = []
          if (state.imagecategorylinksdata) {
               cats = state.imagecategorylinksdata.filter(
                    (i) => i.imagecategoryID == ID
               )
          }
          return cats
     },
     getAllFolders: (state) => {
          let folders = []
          if (state.foldersdata) {
               folders = state.foldersdata
          }
          return folders
     },
}
