export const images = {
     computed: {
          imagedirectory() {
               return this.$store.state.filemanager.imagedirectory
          },
     },
     methods: {
          getImage(ID) {
               let media = this.$store.getters['filemanager/file'](ID)
               let def = this.imagedirectory + 'dummy.png'
               if (media) {
                    return this.imagedirectory + media.path
               } else {
                    return def
               }
          },
     },
}
