import dbService from '@/services/dbService.js'
import bF from '@/services/basicFunctions.js'

export const namespaced = true

export const state = {
     customersdata: [],
     selectedCustomers: [],
}

export const mutations = {
     FILL_CUSTOMERSDATA(state, data) {
          state.customersdata = data
     },
     FILL_SELECTEDCUSTOMERS(state, data) {
          state.selectedCustomers = data
     },
}

export const actions = {
     get_Customers({ commit }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         commit('FILL_CUSTOMERSDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     add_Customer({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         dispatch('get_Customers', {
                              query: 'getCustomers',
                         })
                         return response.data
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_Customer({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200 && response.data == 'Done') {
                         dispatch('get_Customers', {
                              query: 'getCustomers',
                         })
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
}

export const getters = {
     customer: (state) => (ID) => {
          let customer = []
          if (state.customersdata) {
               customer = state.customersdata.find((c) => c.ID == ID)
          }
          return customer
     },
     customers: (state) => {
          let customers = []
          if (state.customersdata) {
               customers = state.customersdata.filter((c) => c.status == 'A')
          }
          return bF.sortList('lastname', customers, true)
     },
     customerList: (state) => {
          let customers = []
          if (state.customersdata) {
               state.customersdata.forEach((c) => {
                    if (c.lastname) {
                         customers.push({
                              value: c.ID,
                              text:
                                   (
                                        c.lastname +
                                        ' ' +
                                        c.firstname +
                                        ' ' +
                                        c.title
                                   ).trim() +
                                   ' (' +
                                   c.city +
                                   ')',
                         })
                    }
               })
          }

          return bF.sortList('text', customers, true)
     },
}
