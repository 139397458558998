<template>
     <div>
          <button class="backBtn" @click="goBack">
               <font-awesome-icon icon="chevron-circle-left" />
          </button>
     </div>
</template>

<script>
export default {
     name: 'backBtn',
     computed: {
          backLink() {
               // use with this <button class="backBtn" @click="goBack" v-show="backLink">
               return this.$store.getters['basics/backLink']
          },
     },
     methods: {
          goBack() {
               // Go back to Overview
               //this.$router.push({ path: this.backLink })
               // Go back -1
               this.$router.go(-1)
          },
     },
}
</script>

<style lang="sass">
@import "@/assets/SASS/main.sass"
.backBtn
     border: none
     background-color: transparent
     color: $darkgrey
     font-size: 1.8rem
     transition: 0.5s
     margin: 0 auto 0 auto
     &:hover
          color: $darkred
          transition: 0.5s
          transform: scale(1.2)
</style>
