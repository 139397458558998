import Vue from 'vue'
import Vuex from 'vuex'
import * as basics from '@/store/modules/basics.js'
import * as users from '@/store/modules/users.js'
import * as customers from '@/store/modules/customers.js'
import * as projects from '@/store/modules/projects.js'
import * as agreements from '@/store/modules/agreements.js'
import * as messages from '@/store/modules/messages.js'
import * as filemanager from '@/store/modules/filemanager.js'
import * as news from '@/store/modules/news.js'

Vue.use(Vuex)

export default new Vuex.Store({
     modules: {
          basics,
          users,
          customers,
          projects,
          agreements,
          messages,
          filemanager,
          news,
     },
     state: {},
     mutations: {},
     actions: {
          init_Data({ dispatch }) {
               dispatch('customers/get_Customers', { query: 'getCustomers' })
               dispatch('projects/get_Projects', { query: 'getProjects' })
               dispatch('agreements/get_Services', { query: 'getServices' })
               dispatch('agreements/get_ServiceCategories', {
                    query: 'getServiceCategories',
               })
               dispatch('projects/get_ProjectUnits', {
                    query: 'getProjectUnits',
               })
               dispatch('agreements/get_Agreements', {
                    query: 'getAgreements',
               })
               dispatch('agreements/get_AgreementUpdates', {
                    query: 'getAgreementUpdates',
               })
               dispatch('filemanager/get_Files', { query: 'getFiles' })
               dispatch('filemanager/get_Folders', {
                    query: 'getFolders',
               })
               dispatch('filemanager/get_ImageCategories', {
                    query: 'getImageCategories',
               })
               dispatch('filemanager/get_ImageCategoryLinks', {
                    query: 'getImageCategoryLinks',
               })
               dispatch('news/get_News', {
                    query: 'getNews',
               })
               dispatch('basics/get_UserData', {
                    query: 'getUserData',
               })
               dispatch('basics/get_AccessInfo', {
                    query: 'getAccessData',
               })
               dispatch('users/get_AllUsers', {
                    query: 'getUsers',
               })
               dispatch('projects/get_Corporates', {
                    query: 'getCorporates',
               })
          },
     },
})
