<template>
     <div>
          <b-modal
               id="ShouldDeleteModal"
               centered
               size="md"
               hide-footer
               :title="deleteinfo.titletext + ' löschen'"
          >
               <div v-if="deleteinfo.deletable">
                    <h2>
                         Soll folgende{{ deleteinfo.typetext }} wirklich
                         gelöscht werden?
                    </h2>
                    <p class="bigfont">"{{ deleteinfo.title }}"</p>
                    <img
                         v-show="deleteinfo.type == 'image'"
                         :src="getImage(deleteinfo.param)"
                         class="mb-4 ml-1"
                         style="width: 100px; border-radius: 5px"
                    /><br />
                    <b-button
                         class="greyBtn"
                         @click="$bvModal.hide('ShouldDeleteModal')"
                         >Abbrechen</b-button
                    >
                    <b-button @click="deleteData" class="ml-4"
                         >Löschen</b-button
                    >
               </div>
               <div v-else>
                    <h2>
                         {{ deleteinfo.typetext }} kann nicht gelöscht werden.
                    </h2>
                    <p>{{ deleteinfo.reason }}</p>
                    <b-button @click="$bvModal.hide('ShouldDeleteModal')"
                         >OK</b-button
                    >
               </div>
          </b-modal>
     </div>
</template>

<script>
import { images } from '@/mixins/images.js'
export default {
     name: 'ShouldDeleteModal',
     computed: {
          deleteinfo() {
               return this.$store.state.basics.deleteinfo
          },
     },
     mixins: [images],
     methods: {
          deleteData() {
               switch (this.deleteinfo.type) {
                    case 'position':
                         this.$bvModal.hide('ServicePositionModal')
                         this.$store.state.agreements.positionsdata.forEach(
                              (p) => {
                                   if (p.ID == this.deleteinfo.ID) {
                                        p.status = 'X'
                                   }
                              }
                         )
                         this.$store
                              .dispatch('agreements/delete_Position', {
                                   query: 'deletePosition',
                                   agreementID: this.deleteinfo.param,
                                   ID: this.deleteinfo.ID,
                              })
                              .then((response) => {
                                   if (response == 'OK') {
                                        this.$bvModal.hide('ShouldDeleteModal')
                                   }
                              })
                         break
                    case 'customer':
                         this.$store
                              .dispatch('customers/update_Customer', {
                                   query: 'deleteCustomer',
                                   ID: this.deleteinfo.param,
                              })
                              .then((response) => {
                                   if (response == 'OK') {
                                        this.$bvModal.hide('ShouldDeleteModal')
                                   }
                              })
                         break
                    case 'user':
                         this.$store
                              .dispatch('users/update_User', {
                                   query: 'deleteUser',
                                   ID: this.deleteinfo.param,
                              })
                              .then((response) => {
                                   if (response == 'OK') {
                                        this.$bvModal.hide('ShouldDeleteModal')
                                   }
                              })
                         break
                    case 'project':
                         this.$store
                              .dispatch('projects/update_Project', {
                                   query: 'deleteProject',
                                   ID: this.deleteinfo.param,
                              })
                              .then((response) => {
                                   if (response == 'OK') {
                                        this.$bvModal.hide('ShouldDeleteModal')
                                   }
                              })
                         break
                    case 'projectUnit':
                         this.$store
                              .dispatch('projects/update_ProjectUnit', {
                                   query: 'deleteProjectUnit',
                                   ID: this.deleteinfo.param,
                              })
                              .then((response) => {
                                   if (response == 'OK') {
                                        this.$bvModal.hide('ShouldDeleteModal')
                                   }
                              })
                         break
                    case 'service':
                         this.$store
                              .dispatch('agreements/update_Service', {
                                   query: 'deleteService',
                                   ID: this.deleteinfo.param,
                              })
                              .then((response) => {
                                   if (response == 'OK') {
                                        this.$bvModal.hide('ShouldDeleteModal')
                                   }
                              })
                         break
                    case 'folder':
                         this.$store
                              .dispatch('filemanager/delete_Folder', {
                                   query: 'deleteFolder',
                                   ID: this.deleteinfo.param,
                              })
                              .then((response) => {
                                   if (response == 'OK') {
                                        this.$bvModal.hide('ShouldDeleteModal')
                                   }
                              })
                         break
                    case 'file':
                         this.$store
                              .dispatch('filemanager/delete_File', {
                                   query: 'deleteFile',
                                   ID: this.deleteinfo.param,
                              })
                              .then((response) => {
                                   if (response == 'OK') {
                                        this.$bvModal.hide('ShouldDeleteModal')
                                   }
                              })
                         break
                    case 'image':
                         this.$store
                              .dispatch('filemanager/delete_Image', {
                                   ID: this.deleteinfo.param,
                              })
                              .then((response) => {
                                   if (response == 'OK') {
                                        this.$bvModal.hide('ShouldDeleteModal')
                                        return 'OK'
                                   }
                              })
                         this.$bvModal.hide('ShouldDeleteModal')
                         break
               }
               this.$store.state.basics.deleteinfo = {}
          },
     },
}
</script>

<style lang="sass"></style>
