<template>
     <div id="app">
          <div class="header row" v-if="loginStatus">
               <div class="logobox col-6">
                    <img src="@/assets/images/logo.png" alt="" />
               </div>
               <div class="col-2"></div>
               <div class="col-2 text-right searchbox">
                    <div class="mt-5" v-show="$route.name != 'SearchResults'">
                         <font-awesome-icon
                              icon="search"
                              class="searchicon mt-5"
                         /><input
                              v-model="gSearchTerm"
                              type="search"
                              placeholder="Suche..."
                              @keyup.enter="
                                   $router.push({
                                        path: `/suchergebnisse`,
                                   })
                              "
                         />
                    </div>
               </div>
               <div class="userbox col-2">
                    <div class="usericon" @click="toggleProfileMenu">
                         <!-- <img
                              src="@/assets/images/dummy.jpg"
                              :alt="usernameline"
                         /> -->
                         <p class="initials">{{ userinitials }}</p>
                    </div>
                    <p class="usernameline">{{ usernameline }}</p>
                    <transition name="down">
                         <div
                              class="profilemenu"
                              v-show="profileMenu === 'open'"
                         >
                              <div class="close" @click="toggleProfileMenu">
                                   <font-awesome-icon icon="times" />
                              </div>
                              <ul>
                                   <router-link
                                        v-for="(menu, index) in profmenu"
                                        :key="index"
                                        :to="menu.link"
                                   >
                                        <li @click="toggleProfileMenu">
                                             <font-awesome-icon
                                                  :icon="menu.icon"
                                                  class="mr-1"
                                             />
                                             {{ menu.title }}
                                        </li> </router-link
                                   ><!-- 
                                   <li @click="logout">
                                   <font-awesome-icon icon="power-off" class="mr-1" />
                                   {{ $t('logout') }}
                                   </li> -->
                              </ul>
                         </div>
                    </transition>
               </div>
          </div>
          <mainnav class="mainnav" v-if="loginStatus" />
          <div class="main">
               <backBtn class="back" v-if="loginStatus" />
               <transition name="blendin">
                    <router-view
                         class="mainview"
                         :class="mainIndent ? 'mainindent' : ''"
                    />
               </transition>
          </div>
          <div class="footer">© {{ year }} LAYER Immobilien & Bau</div>
          <messagebox />
          <shouldDeleteModal />
     </div>
</template>

<script>
import mainnav from '@/components/MainNavigation.vue'
import messagebox from '@/components/Messagebox.vue'
import shouldDeleteModal from '@/modals/ShouldDeleteModal.vue'
import backBtn from '@/components/BackBtn.vue'
export default {
     name: 'app',
     components: {
          mainnav,
          messagebox,
          shouldDeleteModal,
          backBtn,
     },
     data() {
          return {
               profileMenu: 'closed',
               profmenu: [
                    /* { title: 'Benutzerprofil', icon: 'user', link: '' }, */
                    {
                         title: 'Passwort ändern',
                         icon: 'key',
                         link: '/updatepassword',
                    },
               ],
          }
     },
     computed: {
          year() {
               let now = new Date()
               return now.getFullYear()
          },
          mainIndent() {
               let menuStatus = this.$store.state.basics.menuStatus
               if (menuStatus && window.screen.width > 450) {
                    return true
               } else {
                    return false
               }
          },
          loginStatus() {
               return this.$store.state.basics.loginStatus
          },
          usernameline() {
               return this.$store.getters['basics/usernameline']
          },
          userinitials() {
               return this.$store.getters['basics/userinitials']
          },
          gSearchTerm: {
               get: function () {
                    return this.$store.state.basics.gSearchTerm
               },
               set: function (nV) {
                    this.$store.commit('basics/UPDATE_GSEARCHTERM', nV)
               },
          },
     },
     methods: {
          openMenuStart() {
               if (window.screen.width > 450) {
                    this.$store.commit('basics/UPDATE_MENUSTATUS', true)
               }
          },
          toggleProfileMenu() {
               this.profileMenu == 'closed'
                    ? (this.profileMenu = 'open')
                    : (this.profileMenu = 'closed')
          },
     },
     created() {
          this.openMenuStart()
          this.$store.dispatch('init_Data', '', { root: true })
     },
}
</script>

<style lang="sass">
@import "@/assets/SASS/main.sass"
#app
     position: relative
     width: 100%
     margin: 0
     .header
          background-color: white
          position: sticky
          z-index: 100
          top: 0
          left: 0
          width: 100%
          .logobox
               margin: 0
               img
                    width: 80%
                    max-width: 100px
                    margin: 5vh 10%
          .userbox
               .usericon
                    margin: 4vh 10%
                    float: right
                    width: 80%
                    position: relative
                    @media #{$smartphone}
                         margin: 4vh 40% 0 auto
                    img
                         width: 40px
                         height: 40px
                         border-radius: 50%
                         border: 2px solid $lightgrey
                         float: right
                         &:hover
                              border: 2px solid $lightred
                    .initials
                         width: 40px
                         height: 40px
                         border-radius: 50%
                         border: 2px solid $lightgrey
                         float: right
                         font-weight: bold
                         padding: 6px 7px
                         letter-spacing: 0.1rem
                         color: lighten($darkgrey, 40%)
                         &:hover
                              transform: scale(1.3)
                              transition: 0.6s
                              cursor: pointer
               .usernameline
                    position: absolute
                    top: 9vh
                    right: 10%
                    font-size: 0.6rem
                    @media #{$smartphone}
                         right: 40%
     .mainnav
          z-index: 100
     .main
          background-color: lighten($lightgrey, 3%)
          display: block
          .mainview
               margin: 0 0 5vh 0
               padding: 0 10% 2vh 15%
               @media #{$smartphone}
                    padding: 0 5% 2vh 5%
               height: auto
               min-height: 80vh
               display: block
               &.mainindent
                    margin-left: 130px
                    transition: 1s
          .back
               padding:  2vh 10% 0 15%
               margin-left: 125px
               @media #{$smartphone}
                    padding: 2vh 5% 0 5%
                    margin-left: 0
     .profilemenu
        background-color: $darkred
        padding: 0
        border: 1px solid $darkred
        z-index: 500
        position: absolute
        top: 60px
        right: 100px
        border-radius: 5px
        a
            &:hover
                text-decoration: none
        &:hover .close
            display: block
            transition: 0.8s
        .close
            //display: none
            background-color: white
            font-size: 1rem
            position: absolute
            top: -8px
            right: -8px
            z-index: 600
            border: 1px solid lighten($lightgrey, 20%)
            border-radius: 50%
            width: 2.1rem
            height: 2.1rem
            opacity: 1
            color: $lightgrey
            transition: 0.5s
            &:hover
                color: darken($lightgrey, 20%)
                border: 1px solid lighten($lightgrey, 10%)
                transition: 0.5s
            svg
                position: absolute
                top: 50%
                right: 18%
                transform: translate(-50%, -50%)
        ul
            list-style-type: none
            padding-inline-start: 0
            margin-block-start: 0
            margin-block-end: 0
            li
                color: white
                font-size: 0.9rem
                border-bottom: 1px solid white
                padding: 15px 30px
                text-transform: uppercase
                text-align: center
                transition: 0.5s
                cursor: pointer
                a
                    color: white
                    &.router-link-active
                        color: darken($lightred, 15%)
                &:last-of-type
                    border-bottom: none
                    font-weight: bold
                &:hover
                    background-color: white
                    color: $lightred
                    transition: 0.5s

     .footer
          position: fixed
          bottom: 0
          right: 0
          width: 100%
          background-color: $darkgrey
          padding: 5px 5%
          color: white
          z-index: 90
</style>
