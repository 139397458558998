import dbService from '@/services/dbService.js'
import bF from '@/services/basicFunctions.js'

export const namespaced = true

export const state = {
     agreementsdata: [],
     agreementupdates: [],
     positionsdata: [],
     servicesdata: [],
     servicecategoriesdata: [],
     selectedPositions: [],
     unitOptions: [
          { value: 'Stk', text: 'Stück' },
          { value: 'qm', text: 'Quadratmeter' },
          { value: 'lfm', text: 'Laufmeter' },
          { value: 'psch', text: 'Pauschal' },
          { value: 'gesch', text: 'Geschoss' },
     ],
     agreementStatusOptions: [
          'In Bearbeitung',
          'Versendet',
          'Bestätigt',
          'Veraltet',
     ],
     oldAgreement: {},
     mainCategoryID: null,
     subCategoryID: null,
}

export const mutations = {
     FILL_AGREEMENTSDATA(state, data) {
          state.agreementsdata = data
     },
     FILL_AGREEMENTUPDATES(state, data) {
          state.agreementupdates = data
     },
     FILL_POSITIONSDATA(state, data) {
          state.positionsdata = data
     },
     FILL_SERVICESDATA(state, data) {
          state.servicesdata = data
     },
     FILL_SERVICECATEGORIESDATA(state, data) {
          state.servicecategoriesdata = data
     },
     FILL_SELECTEDPOSITIONS(state, data) {
          state.selectedPositions = data
     },
     FILL_OLDAGREEMENT(state, data) {
          state.oldAgreement = data
     },
}

export const actions = {
     get_Agreements({ commit }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         commit('FILL_AGREEMENTSDATA', response.data)
                    }
                    return 'OK'
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_Positions({ commit }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         response.data.forEach((p) => {
                              p.description = bF.decodeHTML(p.description)
                         })
                         commit('FILL_POSITIONSDATA', response.data)
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_Services({ commit }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         response.data.forEach((s) => {
                              s.description = bF.decodeHTML(s.description)
                         })
                         commit('FILL_SERVICESDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_ServiceCategories({ commit }, data) {
          data.query = 'getServiceCategories'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         commit('FILL_SERVICECATEGORIESDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_AgreementUpdates({ commit }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         commit('FILL_AGREEMENTUPDATES', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     add_Agreement({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         dispatch('get_Agreements', {
                              query: 'getAgreements',
                         })
                         return response.data
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     add_Position({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         dispatch('get_Positions', {
                              query: 'getPositions',
                              agreementID: data.agreementID,
                         })
                         dispatch('get_Agreements', {
                              query: 'getAgreements',
                         })
                         return response.data
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     add_Service({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         dispatch('get_Services', {
                              query: 'getServices',
                         })
                         return response.data
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     add_ServiceCategory({ dispatch }, data) {
          data.query = 'addServiceCategory'
          return dbService
               .runQuery(data)
               .then(() => {
                    dispatch('get_ServiceCategories', {
                         query: 'getServiceCategories',
                    })
                    return 'OK'
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_Position({ dispatch }, data) {
          data.description = bF.encodeHTML(data.description)
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200 && response.data == 'Done') {
                         if (data.status != 'X') {
                              dispatch('get_Positions', {
                                   query: 'getPositions',
                                   agreementID: data.agreementID,
                              })
                         }
                         return 'OK'
                    } else {
                         return 'Error'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_Service({ dispatch }, data) {
          if (data.query == 'updateService') {
               data.description = bF.encodeHTML(data.description)
          }
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200 && response.data == 'Done') {
                         dispatch('get_Services', {
                              query: 'getServices',
                         })

                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_ServiceCategory({ dispatch }, data) {
          data.query = 'updateServiceCategory'
          return dbService
               .runQuery(data)
               .then((response) => {
                    console.log(response)
                    if (response.status == 200 && response.data == 'Done') {
                         dispatch('get_ServiceCategories', {
                              query: 'getServiceCategories',
                         })
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     delete_ServiceCategory({ dispatch }, data) {
          data.query = 'deleteServiceCategory'
          return dbService
               .runQuery(data)
               .then((response) => {
                    console.log(response)
                    if (response.status == 200 && response.data == 'Done') {
                         dispatch('get_ServiceCategories', {
                              query: 'getServiceCategories',
                         })
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_Agreement({ dispatch, getters, commit }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    console.log('update: ', response)
                    if (response.status == 200 && response.data == 'Done') {
                         dispatch('get_Agreements', {
                              query: 'getAgreements',
                         }).then((r2) => {
                              console.log(r2)
                              dispatch('get_Positions', {
                                   query: 'getPositions',
                                   agreementID: data.ID,
                              }).then((r3) => {
                                   console.log(r3)
                                   let a = getters['Agreement'](data.ID)
                                   a = { ...a }
                                   commit('FILL_OLDAGREEMENT', a)
                              })
                         })
                         dispatch('get_AgreementUpdates', {
                              query: 'getAgreementUpdates',
                         })
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     delete_Position(base, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200 && response.data == 'Done') {
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     delete_Agreement({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200 && response.data == 'Done') {
                         dispatch('get_Agreements', {
                              query: 'getAgreements',
                         })
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     copy_Position(base, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200 && response.data == 'Done') {
                         return 'OK'
                    } else {
                         return 'Error'
                    }
               })
               .catch((error) => {
                    console.log(error)
                    return 'Error'
               })
     },
     copy_Positions(base, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         Array.isArray(response.data)
                    ) {
                         return 'OK'
                    } else {
                         return 'Error'
                    }
               })
               .catch((error) => {
                    console.log(error)
                    return 'Error'
               })
     },
     copy_Agreement({ dispatch, commit }, data) {
          // Add new Agreement
          let newA = {
               projectUnitID: data.projectunitID,
               description: data.description,
               lastname: data.lastname,
               version: data.version,
               subversion: Number(data.subversion) + 1,
               type: data.type,
               query: 'addAgreement',
          }
          return dispatch('add_Agreement', newA).then((response) => {
               if (Array.isArray(response)) {
                    // Set status of old Agreement to 'Veraltet'
                    let oldA = {
                         query: 'deleteAgreement',
                         ID: data.ID,
                    }
                    dispatch('delete_Agreement', oldA)
                    // Copy all Positions
                    let cPos = {
                         query: 'copyAllPositionsFromAgreement',
                         agreementID_new: response[0].ID,
                         agreementID_old: data.ID,
                    }
                    dispatch('copy_Positions', cPos)
                    // dispatch('copy_Positions', cPos).then((res) => {
                    //      console.log(res)
                    //      if (res == 'Error') {
                    //           commit(
                    //                'messages/ADD_MESSAGE',
                    //                {
                    //                     text:
                    //                          'FEHLER: Positionen konnten nicht kopiert werden! Grund: ' +
                    //                          res,
                    //                     icon: 'times',
                    //                     color: 'red',
                    //                     time: 4500,
                    //                },
                    //                { root: true }
                    //           )
                    //      }
                    // })
                    dispatch('get_Agreements', {
                         query: 'getAgreements',
                    })
                    return response
               } else {
                    commit(
                         'messages/ADD_MESSAGE',
                         {
                              text: 'FEHLER: ' + response,
                              icon: 'times',
                              color: 'red',
                              time: 4500,
                         },
                         { root: true }
                    )
               }
          })
     },
}

export const getters = {
     projectAgreements: (state) => (ID) => {
          let projectAgreements = []
          if (state.agreementsdata) {
               projectAgreements = state.agreementsdata.filter(
                    (a) => a.projectID == ID
               )
          }
          return bF.sortList('title', projectAgreements, true)
     },
     customerAgreements: (state) => (ID) => {
          let customerAgreements = []
          if (state.agreementsdata) {
               customerAgreements = state.agreementsdata.filter(
                    (a) => a.customer1ID == ID || a.customer2ID == ID
               )
          }
          return bF.sortList('title', customerAgreements, true)
     },
     projectUnitAgreements: (state) => (ID) => {
          let puAgreements = []
          if (state.agreementsdata) {
               puAgreements = state.agreementsdata.filter(
                    (a) => a.projectunitID == ID
               )
          }
          return bF.sortList('title', puAgreements, true)
     },
     Agreement: (state) => (ID) => {
          let agreement = []
          if (state.agreementsdata) {
               agreement = state.agreementsdata.find((a) => a.ID == ID)
          }
          return agreement
     },
     positions: (state) => (ID) => {
          let positions = []
          if (state.positionsdata) {
               positions = state.positionsdata.filter(
                    (p) => p.agreementID == ID && p.status == 'A'
               )
          }
          positions.forEach((p) => {
               p.check = false
          })
          return bF.sortList('title', positions, true)
     },
     position: (state) => (ID) => {
          let position = []
          if (state.positionsdata) {
               position = state.positionsdata.find((p) => p.ID == ID)
          }
          return position
     },
     agreementList: (state) => {
          let agreements = []
          if (state.agreementsdata) {
               state.agreementsdata.forEach((a) => {
                    if (a.status != 'Veraltet') {
                         let type = a.type.toString()
                         type = type.charAt(0).toUpperCase() + type.slice(1)
                         type = type.slice(0, -2)
                         agreements.push({
                              value: a.ID,
                              text: type + ': ' + a.title + ' ' + a.customer1,
                              status: a.status,
                         })
                    }
               })
          }
          return bF.sortList('text', agreements, true)
     },
     services: (state) => {
          let services = []
          if (state.servicesdata) {
               services = state.servicesdata
          }
          return bF.sortList('title', services, true)
     },
     service: (state) => (ID) => {
          let service = []
          if (state.servicesdata) {
               service = state.servicesdata.find((s) => s.ID == ID)
          }
          return service
     },
     agreementUpdates: (state) => (ID) => {
          let updates = []
          if (state.agreementupdates) {
               updates = state.agreementupdates.filter((au) => au.refID == ID)
          }
          return updates
     },
     serviceList: (state) => {
          let services = []
          if (state.servicesdata) {
               state.servicesdata.forEach((s) => {
                    services.push({
                         value: s.ID,
                         text: s.title,
                         categorieID: s.categoryID,
                         description: s.description,
                         price: s.price,
                         img: s.img,
                         fileID: s.fileID,
                         unit: s.unit,
                    })
               })
          }
          return bF.sortList('text', services, true)
     },
     serviceCategoryList: (state) => {
          let categories = []
          if (state.servicecategoriesdata) {
               state.servicecategoriesdata.forEach((c) => {
                    if (c.superior == 0) {
                         categories.push({
                              value: c.ID,
                              text: c.title,
                         })
                    } else {
                         let over = state.servicecategoriesdata.find(
                              (s) => s.ID == c.superior
                         )
                         categories.push({
                              value: c.ID,
                              text: over.title + ' - ' + c.title,
                         })
                    }
               })
          }
          // Sorting the list by text
          let output = categories.sort((a, b) => {
               let fa = a.text.toLowerCase(),
                    fb = b.text.toLowerCase()
               if (fa < fb) {
                    return -1
               }
               if (fa > fb) {
                    return 1
               }
               return 0
          })
          return bF.sortList('text', output, true)
     },
     serviceCategoryListBySuperior: (state) => (superior) => {
          let categories = []
          if (state.servicecategoriesdata) {
               state.servicecategoriesdata.forEach((c) => {
                    if (c.superior == superior) {
                         categories.push({
                              value: c.ID,
                              text: c.title,
                         })
                    }
               })
          }
          return bF.sortList('text', categories, true)
     },
     getOldAgreement: (state) => {
          let oA = state.oldAgreement
          delete oA.query
          delete oA.userID
          delete oA.token
          return oA
     },
     servicesByCategoryID: (state) => (ID) => {
          let s = []
          s = state.servicesdata.filter((sd) => sd.categoryID == ID)
          return s
     },
}
