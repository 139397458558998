import dbService from '@/services/dbService.js'
import bF from '@/services/basicFunctions.js'

export const namespaced = true

export const state = {
     usersdata: [],
     user: {},
     levels: [
          { text: 'Administrator', value: 'admin' },
          { text: 'Interner Mitarbeiter', value: 'intern' },
          { text: 'Vertrieb', value: 'sales' },
     ],
     sharepoints: [
          { text: 'Büro Augsburg', value: 'Augsburg' },
          { text: 'Büro München', value: 'München' },
          //{ text: 'Büro Schwabmünchen', value: 'Schwabmünchen' },
          { text: 'Büro SMÜ', value: 'SMÜ' },
          // { text: 'Büro Allgäu', value: 'Allgäu' },
     ],
}

export const mutations = {
     FILL_USERSDATA(state, data) {
          state.usersdata = data
     },
}

export const actions = {
     get_AllUsers({ commit }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         commit('FILL_USERSDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     add_User({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200) {
                         console.log('here')
                         dispatch('get_AllUsers', {
                              query: 'getUsers',
                         })
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_User({ dispatch }, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200 && response.data == 'Done') {
                         dispatch('get_AllUsers', {
                              query: 'getUsers',
                         })
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_Password(base, data) {
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200 && response.data == 'Done') {
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
}

export const getters = {
     allUsers: (state) => {
          let userlist = []
          if (state.usersdata) {
               userlist = state.usersdata
          }
          return userlist
     },
     userList: (state) => {
          let userlist = []
          if (state.usersdata) {
               state.usersdata.forEach((u) => {
                    userlist.push({
                         value: u.ID,
                         text: (u.lastname + ' ' + u.firstname).trim(),
                    })
               })
          }
          return bF.sortList('text', userlist, true)
     },
     activeUserList: (state) => {
          let userlist = []
          if (state.usersdata) {
               state.usersdata.forEach((u) => {
                    if (u.status == 'A') {
                         userlist.push({
                              value: u.ID,
                              text: (u.lastname + ' ' + u.firstname).trim(),
                         })
                    }
               })
          }
          return bF.sortList('text', userlist, true)
     },
     profile: (state) => (ID) => {
          let profile = []
          if (state.usersdata) {
               profile = state.usersdata.find((u) => u.ID == ID)
          }
          return profile
     },
}
