import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/* VUE AXIOS */
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

// VUE FONT AWESOME
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// VUE BOOTSTRAP
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// VUE MOMENT
import moment from 'moment'
import VueMoment from 'vue-moment'
Vue.use(VueMoment, moment)

Vue.config.productionTip = false

new Vue({
     router,
     store,
     render: (h) => h(App),
}).$mount('#app')
